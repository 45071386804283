import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "/var/jenkins_home/workspace/by-admin-vue/vue-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { uniqueId } from 'lodash';
/**
 * @description 给菜单数据补充上 path 字段
 * @description https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */

export function supplementPath(menu) {
  return menu.map(function (e) {
    return _objectSpread(_objectSpread({}, e), {}, {
      path: e.path || uniqueId('d2-menu-empty-')
    }, e.children ? {
      children: supplementPath(e.children)
    } : {});
  });
}
export function getRouters(oldMenus) {
  var routers = [];
  oldMenus.body.forEach(function (element) {
    element.children.forEach(function (res) {
      var router = {
        path: res.path,
        name: res.name,
        meta: {
          title: res.title,
          auth: true
        },
        componentStr: res.component
      };
      routers.push(router);
    });
  });
  return routers;
}
export function getChildrens(childrens) {
  var menus = [];
  childrens.forEach(function (element) {
    var menu = {
      title: element.title,
      icon: element.icon,
      path: element.path
    };
    menus.push(menu);
  });
  return supplementPath(menus);
}
export function getLeftMenus(oldMenus) {
  var menus = [];
  oldMenus.body.forEach(function (element) {
    var menu = {
      title: element.title,
      icon: element.icon,
      path: element.path,
      children: getChildrens(element.children)
    };

    if (element.location === 'left' || element.location === 'all') {
      menus.push(menu);
    }
  });
  return supplementPath(menus);
}
export function getTopMenus(oldMenus) {
  var menus = [];
  oldMenus.body.forEach(function (element) {
    var menu = {
      title: element.title,
      icon: element.icon,
      path: element.path,
      children: getChildrens(element.children)
    };

    if (element.location === 'top' || element.location === 'all') {
      menus.push(menu);
    }
  });
  return supplementPath(menus);
}
export var menuHeader = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}, {
  title: '开发工具',
  icon: 'folder-o',
  children: [{
    path: '/generateConfig',
    title: '生成配置'
  }]
}, {
  title: '系统管理',
  icon: 'folder-o',
  children: [{
    path: '/userlist',
    title: '用户管理'
  }, {
    path: '/roleList',
    title: '角色管理'
  }, {
    path: '/enumeration',
    title: '枚举管理'
  }, {
    path: '/menulist',
    title: '菜单管理'
  }]
}]);
export var menuAside = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}, {
  title: '开发工具',
  icon: 'folder-o',
  children: [{
    path: '/generateConfig',
    title: '生成配置'
  }]
}, {
  title: '系统管理',
  icon: 'folder-o',
  children: [{
    path: '/userlist',
    title: '用户管理'
  }, {
    path: '/roleList',
    title: '角色管理'
  }, {
    path: '/enumeration',
    title: '枚举管理'
  }, {
    path: '/menulist',
    title: '菜单管理'
  }, {
    path: '/logList',
    title: '日志管理'
  }]
}]);