var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: { "padding-bottom": "30px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      staticStyle: { "font-weight": "bold" },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("今日数据")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 会员用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.userCount))
                        ]),
                        _vm.newDatas.dayUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v("+" + _vm._s(_vm.newDatas.dayUserCount))
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 单身会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.singleUserCount))
                        ]),
                        _vm.newDatas.daySingleUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.daySingleUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 家长会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.parentUserCount))
                        ]),
                        _vm.newDatas.dayParentUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayParentUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 关联会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.relationUserCount))
                        ]),
                        _vm.newDatas.dayRelationUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayRelationUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 非关联会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.noRelationUserCount))
                        ]),
                        _vm.newDatas.dayNoRelationUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" +
                                  _vm._s(_vm.newDatas.dayNoRelationUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 过期会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.expireUserCount))
                        ]),
                        _vm.newDatas.dayExpireUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayExpireUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 单身会员日活 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.singleUserDayActive))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 家长会员日活 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.parentUserDayActive))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 访客用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.visitorUserCount))
                        ]),
                        _vm.newDatas.dayVisitorUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayVisitorUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 单身访客用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.singleVisitorUserCount))
                        ]),
                        _vm.newDatas.daySingleVisitorUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" +
                                  _vm._s(_vm.newDatas.daySingleVisitorUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 家长访客用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.parentVisitorUserCount))
                        ]),
                        _vm.newDatas.dayParentVisitorUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" +
                                  _vm._s(_vm.newDatas.dayParentVisitorUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 注销用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.cancelUserCount))
                        ]),
                        _vm.newDatas.dayCancelUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayCancelUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 推广红娘 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.promoteUserCount))
                        ]),
                        _vm.newDatas.dayPromoteUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayPromoteUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 推荐红娘 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.recommendUserCount))
                        ]),
                        _vm.newDatas.dayRecommendUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayRecommendUserCount)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 红娘用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.redUserCount))
                        ]),
                        _vm.newDatas.dayRedUserCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v("+" + _vm._s(_vm.newDatas.dayRedUserCount))
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 红娘用户日活 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.redUserDayActive))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 推送资源 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.pushResource))
                        ]),
                        _vm.newDatas.dayPushResource > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v("+" + _vm._s(_vm.newDatas.dayPushResource))
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 男性资源 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.manPushResource))
                        ]),
                        _vm.newDatas.dayManPushResource > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayManPushResource)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 女性资源 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.womanPushResource))
                        ]),
                        _vm.newDatas.dayWomanPushResource > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayWomanPushResource)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 单身会员推送 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.singlePushResource))
                        ]),
                        _vm.newDatas.daySinglePushResource > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.daySinglePushResource)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 家长会员推送 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.parentPushResource))
                        ]),
                        _vm.newDatas.dayParentPushResource > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayParentPushResource)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 红娘用户推送 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.redPushResource))
                        ]),
                        _vm.newDatas.dayRedPushResource > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" + _vm._s(_vm.newDatas.dayRedPushResource)
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 发送私信 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.letterCount))
                        ]),
                        _vm.newDatas.dayLetterCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v("+" + _vm._s(_vm.newDatas.dayLetterCount))
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 发布动态 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.commentCount))
                        ]),
                        _vm.newDatas.dayCommentCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v("+" + _vm._s(_vm.newDatas.dayCommentCount))
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: {
                    "padding-bottom": "30px",
                    "margin-top": "20px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      staticStyle: { "font-weight": "bold" },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("审核处理")])]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 红娘审核 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.newDatas.reviewCount))
                        ]),
                        _vm.newDatas.dayReviewCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v("+" + _vm._s(_vm.newDatas.dayReviewCount))
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 问题反馈 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(
                            _vm._s(_vm.selectIndexActivityData.feedbackCount)
                          )
                        ]),
                        _vm.selectIndexActivityData.todayFeedbackCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" +
                                  _vm._s(
                                    _vm.selectIndexActivityData
                                      .todayFeedbackCount
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 投诉举报 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(
                            _vm._s(_vm.selectIndexActivityData.complainCount)
                          )
                        ]),
                        _vm.selectIndexActivityData.todayComplainCount > 0
                          ? _c("div", { staticClass: "numberDivRed" }, [
                              _vm._v(
                                "+" +
                                  _vm._s(
                                    _vm.selectIndexActivityData
                                      .todayComplainCount
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  staticStyle: {
                    "padding-bottom": "30px",
                    "margin-top": "20px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      staticStyle: { "font-weight": "bold" },
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", [_vm._v("本月数据")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "font-weight": "bold",
                            padding: "3px 0"
                          },
                          attrs: { icon: "el-icon-download", type: "text" },
                          on: { click: _vm.exportExcel }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 会员用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 单身会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.daySingleUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 家长会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayParentUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 关联会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayRelationUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 非关联会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(
                            _vm._s(_vm.methNewDatas.dayNoRelationUserCount)
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 过期会员 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayExpireUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 单身会员日活 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.singleUserDayActive))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 家长会员日活 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.parentUserDayActive))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 访客用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayVisitorUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 单身访客用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(
                            _vm._s(_vm.methNewDatas.daySingleVisitorUserCount)
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 家长访客用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(
                            _vm._s(_vm.methNewDatas.dayParentVisitorUserCount)
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 注销用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayCancelUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 推广红娘 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayPromoteUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 推荐红娘 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayRecommendUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 红娘用户 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayRedUserCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 红娘用户日活 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.redUserDayActive))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 推送资源 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayPushResource))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 男性资源 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayManPushResource))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 女性资源 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayWomanPushResource))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 单身会员推送 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.daySinglePushResource))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 家长会员推送 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayParentPushResource))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 红娘用户推送 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayRedPushResource))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 发送私信 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayLetterCount))
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 3 }
                    },
                    [
                      _c("div", [_vm._v(" 发布动态 ")]),
                      _c("div", { staticClass: "numberFont" }, [
                        _c("div", { staticClass: "numberDiv" }, [
                          _vm._v(_vm._s(_vm.methNewDatas.dayCommentCount))
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }