/**
 * 会员各种业务数据统计表请求接口
 * @author 王振宇
 * @since 2022-05-10
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_BYMEMBERSTSDATA_PAGE_LIST: function GET_BYMEMBERSTSDATA_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberStsData/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_BYMEMBERSTSDATA_LIST: function GET_BYMEMBERSTSDATA_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberStsData/getList',
        method: 'get',
        params: data
      });
    },
    GET_BYMEMBERSTSDATA_INFO: function GET_BYMEMBERSTSDATA_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberStsData/info',
        method: 'get',
        params: data
      });
    },
    SAVE_BYMEMBERSTSDATA: function SAVE_BYMEMBERSTSDATA() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberStsData/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_BYMEMBERSTSDATA: function UPDATE_BYMEMBERSTSDATA() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberStsData/update',
        method: 'put',
        data: data
      });
    },
    DELETE_BYMEMBERSTSDATA: function DELETE_BYMEMBERSTSDATA() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberStsData/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_BYMEMBERSTSDATA: function DELETE_BATCH_BYMEMBERSTSDATA() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberStsData/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});