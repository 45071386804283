/**
 * 平台返现审核表请求接口
 * @author 王振宇
 * @since 2022-07-03
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_PLATFORMCASHBACKAUDIT_PAGE_LIST: function GET_PLATFORMCASHBACKAUDIT_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_PLATFORMCASHBACKAUDIT_FINANCE_PAGE_LIST: function GET_PLATFORMCASHBACKAUDIT_FINANCE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/getFinancePageList',
        method: 'get',
        params: data
      });
    },
    GET_CASHBACKRECORDPAGELIST_FINANCE_PAGE_LIST: function GET_CASHBACKRECORDPAGELIST_FINANCE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/getCashbackRecordPageList',
        method: 'get',
        params: data
      });
    },
    GET_PLATFORMCASHBACKAUDIT_LIST: function GET_PLATFORMCASHBACKAUDIT_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/getList',
        method: 'get',
        params: data
      });
    },
    GET_PLATFORMCASHBACKAUDIT_SUM_DATA_COUNT: function GET_PLATFORMCASHBACKAUDIT_SUM_DATA_COUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/getSumDataCount',
        method: 'get',
        params: data
      });
    },
    GET_PLATFORMCASHBACKAUDIT_INFO: function GET_PLATFORMCASHBACKAUDIT_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/info',
        method: 'get',
        params: data
      });
    },
    SAVE_PLATFORMCASHBACKAUDIT: function SAVE_PLATFORMCASHBACKAUDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_PLATFORMCASHBACKAUDIT: function UPDATE_PLATFORMCASHBACKAUDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/update',
        method: 'put',
        data: data
      });
    },
    DELETE_PLATFORMCASHBACKAUDIT: function DELETE_PLATFORMCASHBACKAUDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_PLATFORMCASHBACKAUDIT: function DELETE_BATCH_PLATFORMCASHBACKAUDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/deleteBatch',
        method: 'delete',
        data: data
      });
    },
    PAY_MENT_PLATFORMCASHBACKAUDIT: function PAY_MENT_PLATFORMCASHBACKAUDIT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/platformCashBackAudit/payMent',
        method: 'put',
        data: data
      });
    }
  };
});