//
//
//
//
//
export default {
  name: 'StatSts',
  data: function data() {
    return {
      option: {
        title: {
          text: '演示图表',
          subtext: '纯属虚构',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [{
          name: '访问来源',
          type: 'pie',
          radius: '50%',
          data: [{
            value: 1048,
            name: '正常'
          }, {
            value: 735,
            name: '失败'
          }],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      }
    };
  },
  methods: {
    myEcharts: function myEcharts() {
      var myChart = this.$echarts.init(document.getElementById('StatSts'));
      myChart.setOption(this.option);
    }
  },
  created: function created() {
    var thiz = this;
    setTimeout(function () {
      thiz.myEcharts();
    }, 100);
  }
};