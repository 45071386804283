/**
 * 首页接口API
 * @author 王振宇
 * @since 2021-05-15
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_HOME_COUNT: function GET_HOME_COUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/home/count',
        method: 'post',
        params: data
      });
    },
    GET_HOME_getMetCount: function GET_HOME_getMetCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/home/getMetCount',
        method: 'post',
        params: data
      });
    }
  };
});