/**
 * 推送消息表请求接口
 * @author 王振宇
 * @since 2022-04-23
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_PUSHMESSAGE_PAGE_LIST: function GET_PUSHMESSAGE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushMessage/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_PUSHMESSAGE_LIST: function GET_PUSHMESSAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushMessage/getList',
        method: 'get',
        params: data
      });
    },
    GET_PUSHMESSAGE_INFO: function GET_PUSHMESSAGE_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushMessage/info',
        method: 'get',
        params: data
      });
    },
    SAVE_PUSHMESSAGE: function SAVE_PUSHMESSAGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushMessage/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_PUSHMESSAGE: function UPDATE_PUSHMESSAGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushMessage/update',
        method: 'put',
        data: data
      });
    },
    DELETE_PUSHMESSAGE: function DELETE_PUSHMESSAGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushMessage/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_PUSHMESSAGE: function DELETE_BATCH_PUSHMESSAGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushMessage/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});