/**
 * 代理月结算表请求接口
 * @author 王振宇
 * @since 2022-09-24
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_AGENCYMONTHLYSETTLEMENT_PAGE_LIST: function GET_AGENCYMONTHLYSETTLEMENT_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_AGENCYMONTHLYSETTLEMENT_FINANCE_RECORD_PAGE_LIST: function GET_AGENCYMONTHLYSETTLEMENT_FINANCE_RECORD_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/getFinanceRecordPageList',
        method: 'get',
        params: data
      });
    },
    GET_AGENCYMONTHLYSETTLEMENT_FINANCE_PAGE_LIST: function GET_AGENCYMONTHLYSETTLEMENT_FINANCE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/getFinancePageList',
        method: 'get',
        params: data
      });
    },
    GET_AGENCYMONTHLYSETTLEMENT_EXPORT: function GET_AGENCYMONTHLYSETTLEMENT_EXPORT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/export',
        method: 'get',
        params: data
      });
    },
    GET_AGENCYMONTHLYSETTLEMENT_SETTLEMENTEXPORT: function GET_AGENCYMONTHLYSETTLEMENT_SETTLEMENTEXPORT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/settlementExport',
        method: 'get',
        params: data
      });
    },
    GET_AGENCYMONTHLYSETTLEMENT_LIST: function GET_AGENCYMONTHLYSETTLEMENT_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/getList',
        method: 'get',
        params: data
      });
    },
    GET_AGENCYMONTHLYSETTLEMENT_INFO: function GET_AGENCYMONTHLYSETTLEMENT_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/info',
        method: 'get',
        params: data
      });
    },
    SAVE_AGENCYMONTHLYSETTLEMENT: function SAVE_AGENCYMONTHLYSETTLEMENT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_AGENCYMONTHLYSETTLEMENT: function UPDATE_AGENCYMONTHLYSETTLEMENT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/update',
        method: 'put',
        data: data
      });
    },
    DELETE_AGENCYMONTHLYSETTLEMENT: function DELETE_AGENCYMONTHLYSETTLEMENT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_AGENCYMONTHLYSETTLEMENT: function DELETE_BATCH_AGENCYMONTHLYSETTLEMENT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/deleteBatch',
        method: 'delete',
        data: data
      });
    },
    AGENCYMONTHLYSETTLEMENT: function AGENCYMONTHLYSETTLEMENT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/settlement',
        method: 'put',
        params: data
      });
    },
    getAgencyRemainAmountUnsettledCount: function getAgencyRemainAmountUnsettledCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/getAgencyRemainAmountUnsettledCount',
        method: 'post',
        params: data
      });
    },
    getAgencyRemainAmountAlreadySettledCount: function getAgencyRemainAmountAlreadySettledCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMonthlySettlement/getAgencyRemainAmountAlreadySettledCount',
        method: 'post',
        params: data
      });
    }
  };
});