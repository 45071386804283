/**
 * 查询用户充值明细。
 * @author 王振宇
 * @since 2022-08-12
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_MATCHMAKER_SEX_STS: function GET_MATCHMAKER_SEX_STS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/statistics/getMatchmakerSexSts',
        method: 'post',
        data: data
      });
    },
    GET_AGENCY_SEX_STS: function GET_AGENCY_SEX_STS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/statistics/getAgencySexSts',
        method: 'post',
        data: data
      });
    },
    GET_INDEX_TOTAL_STATISTICS: function GET_INDEX_TOTAL_STATISTICS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/IndexTotalStatistics/getMemberIndexTotalStatistics',
        method: 'get',
        params: data
      });
    },
    GET_INDEX_TODAY_TOTAL_STATISTICS: function GET_INDEX_TODAY_TOTAL_STATISTICS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/IndexTotalStatistics/getTodayIndexTotalStatistics',
        method: 'get',
        params: data
      });
    },
    GET_MEMBER_FOLLOW_AND_REMARK: function GET_MEMBER_FOLLOW_AND_REMARK() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFollowAndRemark/getPageList',
        method: 'post',
        data: data
      });
    },
    SELECT_INDEX_ACTIVITY_DATA: function SELECT_INDEX_ACTIVITY_DATA() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/statistics/selectIndexActivityData',
        method: 'post',
        data: data
      });
    }
  };
});