/**
 * 会员动态请求接口
 * @author 王振宇
 * @since 2022-06-02
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_MEMBERDYNAMIC_PAGE_LIST: function GET_MEMBERDYNAMIC_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberDynamic/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBERDYNAMIC_SUM_COUNT: function GET_MEMBERDYNAMIC_SUM_COUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberDynamic/dataCounts',
        method: 'get'
      });
    },
    GET_MEMBERDYNAMIC_LIST: function GET_MEMBERDYNAMIC_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberDynamic/getList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBERDYNAMIC_INFO: function GET_MEMBERDYNAMIC_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberDynamic/info',
        method: 'get',
        params: data
      });
    },
    SAVE_MEMBERDYNAMIC: function SAVE_MEMBERDYNAMIC() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberDynamic/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_MEMBERDYNAMIC: function UPDATE_MEMBERDYNAMIC() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberDynamic/update',
        method: 'put',
        data: data
      });
    },
    DELETE_MEMBERDYNAMIC: function DELETE_MEMBERDYNAMIC() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberDynamic/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_MEMBERDYNAMIC: function DELETE_BATCH_MEMBERDYNAMIC() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberDynamic/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});