/**
 * 礼物设置请求接口
 * @author 王振宇
 * @since 2022-05-21
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_GIFT_PAGE_LIST: function GET_GIFT_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/gift/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_GIFT_LIST: function GET_GIFT_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/gift/getList',
        method: 'get',
        params: data
      });
    },
    GET_GIFT_INFO: function GET_GIFT_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/gift/info',
        method: 'get',
        params: data
      });
    },
    SAVE_GIFT: function SAVE_GIFT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/gift/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_GIFT: function UPDATE_GIFT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/gift/update',
        method: 'put',
        data: data
      });
    },
    DELETE_GIFT: function DELETE_GIFT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/gift/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_GIFT: function DELETE_BATCH_GIFT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/gift/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});