import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.iterator";
import api from '@/api';
/**
 * 购车情况
 * @returns
 */

function getCarPurchase(callBack) {
  var par = {
    name: 'member_car_select'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 验证是否是代理登陆
 */


function isCheckAgencyLogin() {
  var item = JSON.parse(localStorage.getItem('userInfo'));

  if (item.loginType === 'agent') {
    return true;
  } else {
    return false;
  }
}
/**
 * 验证是否是代理登陆
 */


function isCheckBackLogin() {
  var item = JSON.parse(localStorage.getItem('userInfo'));

  if (item.loginType === 'back') {
    return true;
  } else {
    return false;
  }
}
/**
 * 购房情况
 * @returns
 */


function getBuyHouse(callBack) {
  var par = {
    name: 'member_buyHouse_select'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取性别的枚举
 * @returns
 */


function getSexEnums(callBack) {
  var par = {
    name: 'member_sex'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取V认证枚举
 * @returns
 */


function getVCertification(callBack) {
  var par = {
    name: 'member_v'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获得会员类型枚举
 * @returns
 */


function getMemberTypeEumns(callBack) {
  var par = {
    name: 'member_type'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取会员身高
 * @param {*} callBack
 */


function getMemberAllHight(callBack) {
  var data = [];

  for (var index = 140; index <= 210; index++) {
    data.push({
      keys: index + 'CM',
      values: index
    });
  }

  callBack(data);
}
/**
 * 获取婚姻状态
 * @param {*} callBack
 */


function getMbmberMarriages(callBack) {
  var par = {
    name: 'mbmber_marriage_select'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取学历信息
 * @param {*} callBack
 */


function getEducations(callBack) {
  var par = {
    name: 'mbmber_education_select'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 会员属相
 * @param {*} callBack
 */


function getZodiacs(callBack) {
  var par = {
    name: 'member_zodiac'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取会员星座
 * @param {n} callBack
 */


function getConstellations(callBack) {
  var par = {
    name: 'member_constellation'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取会员民族
 * @param {*} callBack
 */


function getNations(callBack) {
  var par = {
    name: 'member_nation'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取政治面貌
 * @param {*} callBack
 */


function getMemberPoliticalOutlooks(callBack) {
  var par = {
    name: 'member_political_outlook'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取家中排行
 * @param {*} callBack
 */


function getMemberRankingAtHomes(callBack) {
  var par = {
    name: 'member_ranking_at_home'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 会员从事职业
 * @param {*} callBack
 */


function getMemberOccupations(callBack) {
  var par = {
    name: 'member_occupation'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 所在行业
 * @param {*} callBack
 */


function getMemberIndustrys(callBack) {
  var par = {
    name: 'member_industry'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 公司类型
 */


function getMemberCompanyTypes(callBack) {
  var par = {
    name: 'member_company_type'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 担任职务
 * @param {*} callBack
 */


function getMemberPosts(callBack) {
  var par = {
    name: 'member_post'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 岗位性质
 * @param {*} callBack
 */


function getMemberPostTypes(callBack) {
  var par = {
    name: 'member_post_type'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 会员年收入
 * @param {*} callBack
 */


function getMemberAnnualIncomes(callBack) {
  var par = {
    name: 'member_annual_income'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 是否吸烟
 * @param {*} callBack
 */


function getMemberSmokes(callBack) {
  var par = {
    name: 'member_smoke'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 是否喝酒
 * @param {*} callBack
 */


function getMemberDrinks(callBack) {
  var par = {
    name: 'member_drink'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 实名认证
 * @param {*} callBack
 */


function getAuthentication(callBack) {
  var par = {
    name: 'member_authentication'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 返现状态
 * @param {*} callBack
 */


function getCashBankAuditStats(callBack) {
  var par = {
    name: 'cash_bank_audit_stats'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 财务返现状态
 * @param {*} callBack
 */


function getFinanceCashBankAuditStats(callBack) {
  var par = {
    name: 'finance_cash_back_stats'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取结算比例
 * @param {*} callBack
 */


function getAgentSettlementProportion(callBack) {
  var par = {
    name: 'agent_settlement_proportion'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * 获取区域类型
 * @param {*} callBack
 */


function getSettingsAreType(callBack) {
  var par = {
    name: 'settings_are_type'
  };
  var per = api.GET_ENUMERATION_LIST(par);
  per.then(callBack, function (err) {
    console.log(err);
  });
}
/**
 * check 枚举key获取返回value
 * @param {*} enums
 * @param {*} key
 * @param {*} nullstr
 * @returns
 */


function checkEnumsKey(enums, key, nullstr) {
  for (var index in enums) {
    var item = enums[index];

    if (item.keys === key) {
      return item.values;
    }
  }

  return nullstr;
}
/**
 * check Value 获取key
 * @param {*} enums
 * @param {*} key
 * @param {*} nullstr
 * @returns
 */


function checkEnumsValue(enums, value, nullstr) {
  for (var index in enums) {
    var item = enums[index];

    if (item.values === value) {
      return item.keys;
    }
  }

  return nullstr;
}
/**
 * 返回参数
 */


var enums = {
  checkEnumsKey: checkEnumsKey,
  sexEnums: getSexEnums,
  memberTypeEumns: getMemberTypeEumns,
  vCertification: getVCertification,
  checkEnumsValue: checkEnumsValue,
  getMemberAllHight: getMemberAllHight,
  getMbmberMarriages: getMbmberMarriages,
  getEducations: getEducations,
  getZodiacs: getZodiacs,
  getConstellations: getConstellations,
  getNations: getNations,
  getMemberPoliticalOutlooks: getMemberPoliticalOutlooks,
  getMemberRankingAtHomes: getMemberRankingAtHomes,
  getMemberOccupations: getMemberOccupations,
  getMemberIndustrys: getMemberIndustrys,
  getMemberCompanyTypes: getMemberCompanyTypes,
  getMemberPostTypes: getMemberPostTypes,
  getMemberPosts: getMemberPosts,
  getMemberAnnualIncomes: getMemberAnnualIncomes,
  getMemberSmokes: getMemberSmokes,
  getMemberDrinks: getMemberDrinks,
  getCarPurchase: getCarPurchase,
  getBuyHouse: getBuyHouse,
  getAuthentication: getAuthentication,
  getCashBankAuditStats: getCashBankAuditStats,
  getFinanceCashBankAuditStats: getFinanceCashBankAuditStats,
  getAgentSettlementProportion: getAgentSettlementProportion,
  getSettingsAreType: getSettingsAreType,
  isCheckAgencyLogin: isCheckAgencyLogin,
  isCheckBackLogin: isCheckBackLogin
};
export { enums };
/**
 * 权限认证
 * @param {*} authority
 * @returns
 */

export function checkauthority(authority) {
  var authoritys = localStorage.getItem('authoritys');

  if (authoritys !== null) {
    authoritys = authoritys.split(',');

    for (var index in authoritys) {
      var data = authoritys[index];

      if (data === authority) {
        return false;
      }
    }
  }

  return true;
}
/**
 * 过滤分润数据
 * @param items
 * @param agentAettlementProportions
 * @returns {*[]}
 */

export function getAgentAettlementProportionsSelectItem(items, agentAettlementProportions) {
  var selectItems = [];

  for (var index in agentAettlementProportions) {
    var item = agentAettlementProportions[index];

    for (var index2 in items) {
      var item2 = items[index2];

      if (item2 === item.keys) {
        var value = item.values.substring(item.values.length - 1, item.values.length);

        if (value === '0') {
          item.values = item.values.substring(0, item.values.length - 1);
        }

        selectItems.push(item);
      }
    }
  }

  return selectItems;
}