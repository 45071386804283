var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        "append-to-body": "",
        visible: _vm.dialogVisible,
        width: "470px",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "status-icon": "",
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "80px"
          }
        },
        [
          _vm.form.type !== 2
            ? _c(
                "el-form-item",
                { attrs: { label: "冻结时间", prop: "freezeTime" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.form.freezeTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "freezeTime", $$v)
                      },
                      expression: "form.freezeTime"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "冻结原因", prop: "remarks" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 2, placeholder: "请输入内容" },
                model: {
                  value: _vm.form.remarks,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remarks", $$v)
                  },
                  expression: "form.remarks"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottomDiv" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")]),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.disabled, type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }