/**
 * 会员私信管理请求接口
 * @author 王振宇
 * @since 2022-11-16
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_MEMBERPRIVATELETTER_PAGE_LIST: function GET_MEMBERPRIVATELETTER_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberPrivateLetter/getPageList',
        method: 'post',
        data: data
      });
    },
    GET_MEMBERPRIVATELETTER_LIST: function GET_MEMBERPRIVATELETTER_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberPrivateLetter/getList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBERPRIVATELETTER_INFO: function GET_MEMBERPRIVATELETTER_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberPrivateLetter/info',
        method: 'get',
        params: data
      });
    },
    SAVE_MEMBERPRIVATELETTER: function SAVE_MEMBERPRIVATELETTER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberPrivateLetter/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_MEMBERPRIVATELETTER: function UPDATE_MEMBERPRIVATELETTER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberPrivateLetter/update',
        method: 'put',
        data: data
      });
    },
    DELETE_MEMBERPRIVATELETTER: function DELETE_MEMBERPRIVATELETTER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberPrivateLetter/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_MEMBERPRIVATELETTER: function DELETE_BATCH_MEMBERPRIVATELETTER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberPrivateLetter/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});