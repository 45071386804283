/**
 * 相亲活动请求接口
 * @author 王振宇
 * @since 2022-06-11
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_BLINDDATE_PAGE_LIST: function GET_BLINDDATE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/blindDate/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_BLINDDATE_LIST: function GET_BLINDDATE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/blindDate/getList',
        method: 'get',
        params: data
      });
    },
    GET_BLINDDATE_INFO: function GET_BLINDDATE_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/blindDate/info',
        method: 'get',
        params: data
      });
    },
    SAVE_BLINDDATE: function SAVE_BLINDDATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/blindDate/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_BLINDDATE: function UPDATE_BLINDDATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/blindDate/update',
        method: 'put',
        data: data
      });
    },
    DELETE_BLINDDATE: function DELETE_BLINDDATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/blindDate/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_BLINDDATE: function DELETE_BATCH_BLINDDATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/blindDate/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});