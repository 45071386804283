/**
 * 省份设置请求接口
 * @author 王振宇
 * @since 2022-04-18
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_PROVINCE_PAGE_LIST: function GET_PROVINCE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/province/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_PROVINCE_LIST: function GET_PROVINCE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/province/getList',
        method: 'get',
        params: data
      });
    },
    GET_PROVINCE_INFO: function GET_PROVINCE_INFO() {
      return request({
        url: '/admin/v1/province/info',
        method: 'get'
      });
    },
    SAVE_PROVINCE: function SAVE_PROVINCE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/province/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_PROVINCE: function UPDATE_PROVINCE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/province/update',
        method: 'put',
        data: data
      });
    },
    DELETE_PROVINCE: function DELETE_PROVINCE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/province/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_PROVINCE: function DELETE_BATCH_PROVINCE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/province/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});