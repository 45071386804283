/**
 * 会员充值档位请求接口
 * @author 王振宇
 * @since 2022-08-12
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_MEMBERRECHARGEPOSITION_PAGE_LIST: function GET_MEMBERRECHARGEPOSITION_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberRechargePosition/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBERRECHARGEPOSITION_LIST: function GET_MEMBERRECHARGEPOSITION_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberRechargePosition/getList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBERRECHARGEPOSITION_INFO: function GET_MEMBERRECHARGEPOSITION_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberRechargePosition/info',
        method: 'get',
        params: data
      });
    },
    SAVE_MEMBERRECHARGEPOSITION: function SAVE_MEMBERRECHARGEPOSITION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberRechargePosition/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_MEMBERRECHARGEPOSITION: function UPDATE_MEMBERRECHARGEPOSITION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberRechargePosition/update',
        method: 'put',
        data: data
      });
    },
    DELETE_MEMBERRECHARGEPOSITION: function DELETE_MEMBERRECHARGEPOSITION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberRechargePosition/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_MEMBERRECHARGEPOSITION: function DELETE_BATCH_MEMBERRECHARGEPOSITION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberRechargePosition/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});