//
//
//
//
//
//
//
//
//
import AgencyInfo from './../agencyInfo/index';
import MaentyInfo from './../matchmaker/info/index';
import IndexStsInfo from './../statistics/index/index';
export default {
  name: 'MainIndex',
  components: {
    AgencyInfo: AgencyInfo,
    MaentyInfo: MaentyInfo,
    IndexStsInfo: IndexStsInfo
  },
  data: function data() {
    return {
      loginType: 1
    };
  },
  methods: {
    checkLogin: function checkLogin() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));

      if (userInfo.loginType === 'agent') {
        this.loginType = 1;
      } else if (userInfo.loginType === 'back') {
        this.loginType = 3;
      } else {
        this.loginType = 2;
      }
    }
  },
  created: function created() {
    this.checkLogin();
  }
};