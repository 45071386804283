import "core-js/modules/es.array.map";
import _objectSpread from "/var/jenkins_home/workspace/by-admin-vue/vue-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { mapState } from 'vuex';
import menuMixin from '../mixin/menu';
import { createMenu } from '../libs/util.menu';
import BScroll from 'better-scroll';
export default {
  name: 'd2-layout-header-aside-menu-side',
  mixins: [menuMixin],
  render: function render(h) {
    var _this = this;

    var path = this.$route.fullPath;
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (userInfo.loginType === 'agent') {
      path = 'agencyInfo';
    } else if (userInfo.loginType === 'matchmaker') {
      path = 'matchmakerInfo';
    }

    return h("div", {
      "class": "d2-layout-header-aside-menu-side"
    }, [h("el-menu", {
      "attrs": {
        "collapse": this.asideCollapse,
        "collapseTransition": this.asideTransition,
        "uniqueOpened": true,
        "defaultActive": path
      },
      "ref": "menu",
      "on": {
        "select": this.handleMenuSelect
      }
    }, [this.aside.map(function (menu) {
      return createMenu.call(_this, h, menu);
    })]), this.aside.length === 0 && !this.asideCollapse ? h("div", {
      "class": "d2-layout-header-aside-menu-empty",
      "attrs": {
        "flex": "dir:top main:center cross:center"
      }
    }, [h("d2-icon", {
      "attrs": {
        "name": "inbox"
      }
    }), h("span", ["\u6CA1\u6709\u4FA7\u680F\u83DC\u5355"])]) : null]);
  },
  data: function data() {
    return {
      asideHeight: 300,
      BS: null
    };
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['aside', 'asideCollapse', 'asideTransition'])),
  watch: {
    // 折叠和展开菜单的时候销毁 better scroll
    asideCollapse: function asideCollapse(val) {
      var _this2 = this;

      this.scrollDestroy();
      setTimeout(function () {
        _this2.scrollInit();
      }, 500);
    }
  },
  mounted: function mounted() {
    this.scrollInit();
  },
  beforeDestroy: function beforeDestroy() {
    this.scrollDestroy();
  },
  methods: {
    scrollInit: function scrollInit() {
      this.BS = new BScroll(this.$el, {
        mouseWheel: true,
        click: true // 如果你愿意可以打开显示滚动条
        // scrollbar: {
        //   fade: true,
        //   interactive: false
        // }

      });
    },
    scrollDestroy: function scrollDestroy() {
      // https://github.com/d2-projects/d2-admin/issues/75
      try {
        this.BS.destroy();
      } catch (e) {
        delete this.BS;
        this.BS = null;
      }
    }
  }
};