/**
 * <日志管理请求接口
 * @author 王振宇
 * @since 2021-04-08
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_LOG_PAGE_LIST: function GET_LOG_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/log/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_LOG_INFO: function GET_LOG_INFO() {
      return request({
        url: '/admin/v1/log/info',
        method: 'get'
      });
    },
    SAVE_LOG: function SAVE_LOG() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/log/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_LOG: function UPDATE_LOG() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/log/update',
        method: 'put',
        data: data
      });
    },
    DELETE_LOG: function DELETE_LOG() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/log/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_LOG: function DELETE_BATCH_LOG() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/log/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});