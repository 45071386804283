var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "400px",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "新密码", prop: "passWord" } },
            [
              _c("el-input", {
                attrs: { "show-password": "", placeholder: "请输入密码" },
                model: {
                  value: _vm.form.passWord,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "passWord", $$v)
                  },
                  expression: "form.passWord"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "确认密码", prop: "rePassWord" } },
            [
              _c("el-input", {
                attrs: { "show-password": "", placeholder: "请输入确认密码" },
                model: {
                  value: _vm.form.rePassWord,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "rePassWord", $$v)
                  },
                  expression: "form.rePassWord"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取 消")]),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.update } },
        [_vm._v("确 定")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }