/**
 * 红娘分润表请求接口
 * @author 王振宇
 * @since 2022-11-12
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_BYMATCHMAKERPROFITDISTRIBUTION_PAGE_LIST: function GET_BYMATCHMAKERPROFITDISTRIBUTION_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerProfitDistribution/getPageList',
        method: 'post',
        data: data
      });
    },
    GET_BYMATCHMAKERPROFITDISTRIBUTION_LIST: function GET_BYMATCHMAKERPROFITDISTRIBUTION_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerProfitDistribution/getList',
        method: 'get',
        params: data
      });
    },
    GET_BYMATCHMAKERPROFITDISTRIBUTION_INFO: function GET_BYMATCHMAKERPROFITDISTRIBUTION_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerProfitDistribution/info',
        method: 'get',
        params: data
      });
    },
    SAVE_BYMATCHMAKERPROFITDISTRIBUTION: function SAVE_BYMATCHMAKERPROFITDISTRIBUTION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerProfitDistribution/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_BYMATCHMAKERPROFITDISTRIBUTION: function UPDATE_BYMATCHMAKERPROFITDISTRIBUTION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerProfitDistribution/update',
        method: 'put',
        data: data
      });
    },
    DELETE_BYMATCHMAKERPROFITDISTRIBUTION: function DELETE_BYMATCHMAKERPROFITDISTRIBUTION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerProfitDistribution/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_BYMATCHMAKERPROFITDISTRIBUTION: function DELETE_BATCH_BYMATCHMAKERPROFITDISTRIBUTION() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerProfitDistribution/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});