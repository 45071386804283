/**
 * 代理会员请求接口
 * @author 王振宇
 * @since 2022-06-19
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_AGENCYMEMBER_PAGE_LIST: function GET_AGENCYMEMBER_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/getPageList',
        method: 'post',
        data: data
      });
    },
    GET_AGENCYMEMBER_LIST: function GET_AGENCYMEMBER_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/getList',
        method: 'get',
        params: data
      });
    },
    GET_AGENCYMEMBER_SUM_COUNT: function GET_AGENCYMEMBER_SUM_COUNT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/getAgencySumCount',
        method: 'get',
        params: data
      });
    },
    GET_AGENCYMEMBER_INFO: function GET_AGENCYMEMBER_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/info',
        method: 'get',
        params: data
      });
    },
    SAVE_AGENCYMEMBER: function SAVE_AGENCYMEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/save',
        method: 'post',
        data: data
      });
    },
    AGENCY_MY_RECOMMEND_PAGE_LIST: function AGENCY_MY_RECOMMEND_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/agencyMyRecommendPageList',
        method: 'post',
        data: data
      });
    },
    UPDATE_AGENCYMEMBER: function UPDATE_AGENCYMEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/update',
        method: 'put',
        data: data
      });
    },
    UPDATE_PASSWORD: function UPDATE_PASSWORD() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/updatePassWord',
        method: 'put',
        data: data
      });
    },
    FROZEO_AGENCYMEMBER: function FROZEO_AGENCYMEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/frozen',
        method: 'put',
        data: data
      });
    },
    THAW_AGENCYMEMBER: function THAW_AGENCYMEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/thaw',
        method: 'put',
        data: data
      });
    },
    DELETE_BATCH_AGENCYMEMBER: function DELETE_BATCH_AGENCYMEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/agencyMember/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});