var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加用户",
        visible: _vm.dialogVisible,
        width: "40%",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "status-icon": "",
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "inputText",
                staticStyle: { width: "70%" },
                attrs: { disabled: _vm.disabled, autocomplete: "off" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save()
                  }
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户名称", prop: "userName" } },
            [
              _c("el-input", {
                staticClass: "inputText",
                staticStyle: { width: "70%" },
                attrs: { disabled: _vm.disabled, autocomplete: "off" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save()
                  }
                },
                model: {
                  value: _vm.form.userName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户密码", prop: "passWord" } },
            [
              _c("el-input", {
                staticClass: "inputText",
                staticStyle: { width: "70%" },
                attrs: {
                  disabled: _vm.disabled,
                  type: "password",
                  autocomplete: "off"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save()
                  }
                },
                model: {
                  value: _vm.form.passWord,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "passWord", $$v)
                  },
                  expression: "form.passWord"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色分配", prop: "roleList" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.roleList,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "roleList", $$v)
                    },
                    expression: "form.roleList"
                  }
                },
                _vm._l(_vm.roleList, function(item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.id,
                      attrs: {
                        disabled: _vm.disabled,
                        checked: item.checked,
                        label: item.id
                      }
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottomDiv" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")]),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.disabled, type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }