var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "40%",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("el-form", {
        ref: "form",
        attrs: {
          "status-icon": "",
          model: _vm.form,
          rules: _vm.rules,
          "label-width": "80px"
        }
      }),
      _c(
        "div",
        { staticClass: "bottomDiv" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")]),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.disabled, type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }