/**
 * 钱包请求接口
 * @author 王振宇
 * @since 2022-05-21
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_WALLETRECHARGE_PAGE_LIST: function GET_WALLETRECHARGE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      return request({
        url: '/admin/v1/walletRecharge/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_WALLETRECHARGE_LIST: function GET_WALLETRECHARGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      return request({
        url: '/admin/v1/walletRecharge/getList',
        method: 'get',
        params: data
      });
    },
    GET_WALLETRECHARGE_INFO: function GET_WALLETRECHARGE_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      return request({
        url: '/admin/v1/walletRecharge/info',
        method: 'get',
        params: data
      });
    },
    SAVE_WALLETRECHARGE: function SAVE_WALLETRECHARGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      return request({
        url: '/admin/v1/walletRecharge/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_WALLETRECHARGE: function UPDATE_WALLETRECHARGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      return request({
        url: '/admin/v1/walletRecharge/update',
        method: 'put',
        data: data
      });
    },
    DELETE_WALLETRECHARGE: function DELETE_WALLETRECHARGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      return request({
        url: '/admin/v1/walletRecharge/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_WALLETRECHARGE: function DELETE_BATCH_WALLETRECHARGE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      return request({
        url: '/admin/v1/walletRecharge/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});