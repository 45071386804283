/**
 * 会员请求接口
 * @author 王振宇
 * @since 2022-04-17
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_SELECT_MANGER_MEMBER_TOURISTS_PAGE_LIST: function GET_SELECT_MANGER_MEMBER_TOURISTS_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/select/getMemberList',
        method: 'post',
        data: data
      });
    }
  };
});