import axios from 'axios';
import jsFileDownload from 'js-file-download';
var baseApi = process.env.VUE_APP_API;
/**
 * 下载文件
 * @param url 地址
 * @param fileName 文件名
 */

function download(url) {
  var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未知文件';
  var el = document.createElement('a');
  el.style.display = 'none';
  el.setAttribute('target', '_blank');
  fileName && el.setAttribute('download', fileName);
  el.href = url;
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}
/**
 * 导出excel
 * @param url 接口地址
 * @param data 请求参数
 * @param fileName 文件名称
 */


function excelExport(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var fileName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '未知文件';
  var userInfo = JSON.parse(localStorage.getItem('userInfo'));
  data.operatorUserId = userInfo.id;
  data.loginType = userInfo.loginType;
  axios({
    method: 'post',
    url: baseApi + url,
    responseType: 'blob',
    data: data
  }).then(function (res) {
    if (res && res.data) {
      jsFileDownload(res.data, fileName);
    }
  });
}
/**
 * 返回参数
 */


var downloadUtil = {
  download: download,
  excelExport: excelExport
};
export { downloadUtil };