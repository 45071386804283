import { render, staticRenderFns } from "./platform.vue?vue&type=template&id=33d2ac3b&"
import script from "./platform.vue?vue&type=script&lang=js&"
export * from "./platform.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/by-admin-vue/vue-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33d2ac3b')) {
      api.createRecord('33d2ac3b', component.options)
    } else {
      api.reload('33d2ac3b', component.options)
    }
    module.hot.accept("./platform.vue?vue&type=template&id=33d2ac3b&", function () {
      api.rerender('33d2ac3b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/components/updatePassWord/platform.vue"
export default component.exports