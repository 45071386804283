var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "开通授权",
        visible: _vm.dialogVisible,
        width: "30%",
        "append-to-body": "",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "status-icon": "",
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "授权类型", prop: "authorizationTypeList" } },
            [
              _c(
                "el-checkbox-group",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择授权类型"
                  },
                  on: { change: _vm.change },
                  model: {
                    value: _vm.form.authorizationTypeList,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "authorizationTypeList", $$v)
                    },
                    expression: "form.authorizationTypeList"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 5 } }, [
                    _vm._v("总代授权")
                  ]),
                  _c("el-checkbox", { attrs: { label: 2 } }, [
                    _vm._v("代理授权")
                  ]),
                  _c("el-checkbox", { attrs: { label: 3 } }, [
                    _vm._v("红娘推广授权")
                  ]),
                  _c("el-checkbox", { attrs: { label: 4 } }, [
                    _vm._v("红娘推送授权")
                  ]),
                  _c("el-checkbox", { attrs: { label: 1 } }, [
                    _vm._v("体验授权")
                  ]),
                  _c("el-checkbox", { attrs: { label: -1 } }, [
                    _vm._v("暂不授权")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.flag
            ? _c(
                "el-form-item",
                { attrs: { label: "全局关联账号" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请输入要关联的后台管理账号"
                    },
                    model: {
                      value: _vm.form.authorizationUserName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "authorizationUserName", $$v)
                      },
                      expression: "form.authorizationUserName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.flag
            ? _c(
                "el-form-item",
                { attrs: { label: "代理关联账号" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请输入要关联的后台代理账号"
                    },
                    model: {
                      value: _vm.form.authorizationAgencyUserName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "authorizationAgencyUserName", $$v)
                      },
                      expression: "form.authorizationAgencyUserName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.flag
            ? _c(
                "el-form-item",
                { attrs: { label: "红娘关联账号" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请输入要关联的后台红娘账号"
                    },
                    model: {
                      value: _vm.form.authorizationMatchmakerUserName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "authorizationMatchmakerUserName",
                          $$v
                        )
                      },
                      expression: "form.authorizationMatchmakerUserName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.flag
            ? _c(
                "el-form-item",
                { attrs: { label: "浏览权限", prop: "pushAuditSts" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.reviewAuditSts,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "reviewAuditSts", $$v)
                        },
                        expression: "form.reviewAuditSts"
                      }
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "限制" } }),
                      _c("el-option", { attrs: { value: 1, label: "不限制" } })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.flag
            ? _c(
                "el-form-item",
                { attrs: { label: "推送权限", prop: "pushAuditSts" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.pushAuditSts,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "pushAuditSts", $$v)
                        },
                        expression: "form.pushAuditSts"
                      }
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "限制" } }),
                      _c("el-option", { attrs: { value: 1, label: "不限制" } })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottomDiv" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("关闭")]),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.disabled,
                disabled: _vm.disabled,
                type: "primary"
              },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }