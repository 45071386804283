/**
 * ��Ա�����¼������ӿ�
 * @author ������
 * @since 2022-05-12
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_MEMBERFREEZERECORDS_TAWN_PAGE_LIST: function GET_MEMBERFREEZERECORDS_TAWN_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFreezeRecords/getTawnPageList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBERFREEZERECORDS_PAGE_LIST: function GET_MEMBERFREEZERECORDS_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFreezeRecords/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBERFREEZERECORDS_LIST: function GET_MEMBERFREEZERECORDS_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFreezeRecords/getList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBERFREEZERECORDS_INFO: function GET_MEMBERFREEZERECORDS_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFreezeRecords/info',
        method: 'get',
        params: data
      });
    },
    SAVE_MEMBERFREEZERECORDS: function SAVE_MEMBERFREEZERECORDS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFreezeRecords/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_MEMBERFREEZERECORDS: function UPDATE_MEMBERFREEZERECORDS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFreezeRecords/update',
        method: 'put',
        data: data
      });
    },
    DELETE_MEMBERFREEZERECORDS: function DELETE_MEMBERFREEZERECORDS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFreezeRecords/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_MEMBERFREEZERECORDS: function DELETE_BATCH_MEMBERFREEZERECORDS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/memberFreezeRecords/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});