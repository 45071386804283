/**
 * 红娘结算功能
 * @author 王振宇
 * @since 2022-11-12
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_MATCHMAKERMONTHLYSETTLEMENT_PAGE_LIST: function GET_MATCHMAKERMONTHLYSETTLEMENT_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerMonthlySettlement/getPageList',
        method: 'post',
        data: data
      });
    },
    MATCHMAKERMONTHLYSETTLEMENT: function MATCHMAKERMONTHLYSETTLEMENT() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerMonthlySettlement/settlement',
        method: 'put',
        params: data
      });
    },
    MATCHMAKERMONTHLYSETTLEMENTAll: function MATCHMAKERMONTHLYSETTLEMENTAll() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerMonthlySettlement/settlementAll',
        method: 'post',
        params: data
      });
    },
    getUnsettledTotalAccount: function getUnsettledTotalAccount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerMonthlySettlement/getUnsettledTotalAccount',
        method: 'post',
        params: data
      });
    },
    getSettledTotalAccount: function getSettledTotalAccount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerMonthlySettlement/getSettledTotalAccount',
        method: 'post',
        params: data
      });
    },
    byMatchmakerMonthlySettlementUpdate: function byMatchmakerMonthlySettlementUpdate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMatchmakerMonthlySettlement/update',
        method: 'put',
        data: data
      });
    }
  };
});