/**
 * 会员请求接口
 * @author 王振宇
 * @since 2022-04-17
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_AGENCY_MEMBER_TOURISTS_PAGE_LIST: function GET_AGENCY_MEMBER_TOURISTS_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getAgencyMemberTouristsPageList',
        method: 'get',
        params: data
      });
    },
    getMatchmakerAuditPageList: function getMatchmakerAuditPageList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getMatchmakerAuditPageList',
        method: 'get',
        params: data
      });
    },
    getMatchmakerMemberPageList: function getMatchmakerMemberPageList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getMatchmakerMemberPageList',
        method: 'get',
        params: data
      });
    },
    GET_MATCHMAKER_MEMBER_STS_PAGE_LIST: function GET_MATCHMAKER_MEMBER_STS_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getMatchmakerMemberStsPageList',
        method: 'post',
        data: data
      });
    },
    GET_MATCHMAKER_TOUR_MEMBER_STS_PAGE_LIST: function GET_MATCHMAKER_TOUR_MEMBER_STS_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getMatchmakerTouristsMemberStsPageList',
        method: 'post',
        data: data
      });
    },
    GET_AGENCY_MEMBER_STS_PAGE_LIST: function GET_AGENCY_MEMBER_STS_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getAgencyMemberStsPageList',
        method: 'post',
        data: data
      });
    },
    GET_MEMBER_PAGE_LIST: function GET_MEMBER_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBER_Tourists_PAGE_LIST: function GET_MEMBER_Tourists_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getTouristsPageList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBER_LOG_OUT_PAGE_LIST: function GET_MEMBER_LOG_OUT_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getLogOutPageList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBER_SUM_COUNT: function GET_MEMBER_SUM_COUNT() {
      return request({
        url: '/admin/v1/member/getSumDataCounts',
        method: 'get'
      });
    },
    GET_MEMBER_LIST: function GET_MEMBER_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/getList',
        method: 'get',
        params: data
      });
    },
    GET_MEMBER_INFO: function GET_MEMBER_INFO() {
      return request({
        url: '/admin/v1/member/info',
        method: 'get'
      });
    },
    SAVE_MEMBER: function SAVE_MEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_MEMBER: function UPDATE_MEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/update',
        method: 'put',
        data: data
      });
    },
    DELETE_MEMBER: function DELETE_MEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_MEMBER: function DELETE_BATCH_MEMBER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/deleteBatch',
        method: 'delete',
        data: data
      });
    },
    GIVE_MEMBER_TIME: function GIVE_MEMBER_TIME() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/giveMemberTime',
        method: 'post',
        data: data
      });
    },
    ADD_MEMBER_CURRENCY: function ADD_MEMBER_CURRENCY() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/addMemberCurrency',
        method: 'post',
        data: data
      });
    },
    ADD_ANGLE_OF_AFFINITY_NUM: function ADD_ANGLE_OF_AFFINITY_NUM() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/addAngleOfAffinityNum',
        method: 'post',
        data: data
      });
    },
    ADD_MEMBER_WARNING: function ADD_MEMBER_WARNING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/addWarning',
        method: 'post',
        data: data
      });
    },
    UPDATE_MEMBER_DISPLAY: function UPDATE_MEMBER_DISPLAY() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/updateMemberDisplaySts',
        method: 'post',
        data: data
      });
    },
    updateMatchmakerReview: function updateMatchmakerReview() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/updateMatchmakerReview',
        method: 'post',
        data: data
      });
    },

    /**
     * 批量删除会员信息。
     * @param data
     * @returns {*}
     */
    batchRemoveMember: function batchRemoveMember() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/member/batchRemoveMember',
        method: 'post',
        data: data
      });
    }
  };
});