//
//
//
//
//
export default {
  name: 'Category',
  data: function data() {
    return {
      option: {
        title: {
          text: '演示图表',
          subtext: '纯属虚构'
        },
        legend: {},
        tooltip: {},
        dataset: {
          source: [['product', '2015', '2016', '2017'], ['Matcha Latte', 43.3, 85.8, 93.7], ['Milk Tea', 83.1, 73.4, 55.1], ['Cheese Cocoa', 86.4, 65.2, 82.5], ['Walnut Brownie', 72.4, 53.9, 39.1]]
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {},
        series: [{
          type: 'bar'
        }, {
          type: 'bar'
        }, {
          type: 'bar'
        }]
      }
    };
  },
  methods: {
    myEcharts: function myEcharts() {
      var myChart = this.$echarts.init(document.getElementById('category'));
      myChart.setOption(this.option);
    }
  },
  created: function created() {
    var thiz = this;
    setTimeout(function () {
      thiz.myEcharts();
    }, 100);
  }
};