/**
 * 基本设置请求接口
 * @author 王振宇
 * @since 2022-05-03
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_BASICSETTING_PAGE_LIST: function GET_BASICSETTING_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/basicSetting/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_BASICSETTING_LIST: function GET_BASICSETTING_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/basicSetting/getList',
        method: 'get',
        params: data
      });
    },
    GET_BASICSETTING_INFO: function GET_BASICSETTING_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/basicSetting/info',
        method: 'get',
        params: data
      });
    },
    SAVE_BASICSETTING: function SAVE_BASICSETTING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/basicSetting/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_BASICSETTING: function UPDATE_BASICSETTING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/basicSetting/update',
        method: 'put',
        data: data
      });
    },
    DELETE_BASICSETTING: function DELETE_BASICSETTING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/basicSetting/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_BASICSETTING: function DELETE_BATCH_BASICSETTING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/basicSetting/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});