/**
 * 开通城市请求接口
 * @author 王振宇
 * @since 2022-05-18
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_BYCITYOPENING_PAGE_LIST: function GET_BYCITYOPENING_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_BYCITYOPENING_TREE_LIST: function GET_BYCITYOPENING_TREE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/getTreeList',
        method: 'get',
        params: data
      });
    },
    GET_BYCITYOPENING_SELECT_TREE_LIST: function GET_BYCITYOPENING_SELECT_TREE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/getSelectTreeList',
        method: 'get',
        params: data
      });
    },
    GET_BYCITYOPENING_LIST: function GET_BYCITYOPENING_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/getList',
        method: 'get',
        params: data
      });
    },
    GET_BYCITYOPENING_INFO: function GET_BYCITYOPENING_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/info',
        method: 'get',
        params: data
      });
    },
    SAVE_BYCITYOPENING: function SAVE_BYCITYOPENING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_BYCITYOPENING: function UPDATE_BYCITYOPENING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/update',
        method: 'put',
        data: data
      });
    },
    DELETE_BYCITYOPENING: function DELETE_BYCITYOPENING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_BYCITYOPENING: function DELETE_BATCH_BYCITYOPENING() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCityOpening/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});