//
//
//
//
//
export default {
  name: 'LogSts',
  data: function data() {
    return {
      option: {
        title: {
          text: '演示图表',
          subtext: '纯属虚构'
        },
        tooltip: {},
        legend: {
          data: ['销量']
        },
        xAxis: {
          data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
        },
        yAxis: {},
        series: [{
          name: '销量',
          type: 'bar',
          data: [5, 20, 36, 10, 10, 20]
        }]
      }
    };
  },
  methods: {
    myEcharts: function myEcharts() {
      var myChart = this.$echarts.init(document.getElementById('logSts'));
      myChart.setOption(this.option);
    }
  },
  created: function created() {
    var thiz = this;
    setTimeout(function () {
      thiz.myEcharts();
    }, 100);
  }
};