/**
 * 运营设置请求接口
 * @author 王振宇
 * @since 2022-08-13
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_OPERATIONSETTINGS_PAGE_LIST: function GET_OPERATIONSETTINGS_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/operationSettings/getPageList',
        method: 'post',
        data: data
      });
    },
    GET_OPERATIONSETTINGS_LIST: function GET_OPERATIONSETTINGS_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/operationSettings/getList',
        method: 'get',
        params: data
      });
    },
    GET_OPERATIONSETTINGS_INFO: function GET_OPERATIONSETTINGS_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/operationSettings/info',
        method: 'get',
        params: data
      });
    },
    SAVE_OPERATIONSETTINGS: function SAVE_OPERATIONSETTINGS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/operationSettings/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_OPERATIONSETTINGS: function UPDATE_OPERATIONSETTINGS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/operationSettings/update',
        method: 'put',
        data: data
      });
    },
    DELETE_OPERATIONSETTINGS: function DELETE_OPERATIONSETTINGS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/operationSettings/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_OPERATIONSETTINGS: function DELETE_BATCH_OPERATIONSETTINGS() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/operationSettings/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});