var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { staticClass: "d2-mr", attrs: { size: "small" } },
    [
      _c("span", { staticClass: "btn-text" }, [
        _vm._v(_vm._s(_vm.info.name ? "你好 " + _vm.info.name : "未登录"))
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.editPassWord($event)
                }
              }
            },
            [_c("i", { staticClass: "el-icon-edit" }), _vm._v(" 修改密码 ")]
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.logOff($event)
                }
              }
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "power-off" }
              }),
              _vm._v(" 退出登录 ")
            ],
            1
          )
        ],
        1
      ),
      _c("UpdatePassWord", {
        ref: "updatePassWord",
        attrs: { disabled: _vm.editDisabled, dialogVisible: _vm.dialogVisible },
        on: { close: _vm.close }
      }),
      _c("AgencyPassWord", {
        ref: "agencyUpdatePassWord",
        attrs: {
          disabled: _vm.agencyEditDisabled,
          dialogVisible: _vm.agencyDialogVisible
        },
        on: { close: _vm.close }
      }),
      _c("MatchmakerPassWord", {
        ref: "matchmakerPassWord",
        attrs: {
          disabled: _vm.matchmakerEditDisabled,
          dialogVisible: _vm.matchmakerDialogVisible
        },
        on: { close: _vm.close }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }