var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _vm.loginType === 1 ? _c("agency-info") : _vm._e(),
          _vm.loginType === 2 ? _c("maenty-info") : _vm._e(),
          _vm.loginType === 3 ? _c("index-sts-info") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }