export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_GENERATE_CONFIG: function GET_GENERATE_CONFIG() {
      return request({
        url: '/admin/generate/getConfig',
        method: 'get'
      });
    },
    SAVE_GENERATE_CONFIG: function SAVE_GENERATE_CONFIG() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/generate/saveConfig',
        method: 'post'
      });
    },
    GET_TABLE_LIST: function GET_TABLE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/generate/getTableList',
        method: 'get',
        params: data
      });
    },
    GET_TABLE_INFO: function GET_TABLE_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/generate/getTableInfo',
        method: 'get',
        params: data
      });
    },
    GENERATE: function GENERATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/generate/generate',
        method: 'post',
        data: data
      });
    }
  };
});