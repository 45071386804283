/**
 * 会员资源库请求接口
 * @author 王振宇
 * @since 2024-01-18
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_BYMEMBERRESOURCE_PAGE_LIST: function GET_BYMEMBERRESOURCE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/getPageList',
        method: 'get',
        params: data
      });
    },
    getYesterDayMemberResourcePushCount: function getYesterDayMemberResourcePushCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/getYesterDayMemberResourcePushCount',
        method: 'get',
        params: data
      });
    },
    getYesterDayMemberResourceCount: function getYesterDayMemberResourceCount() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/getYesterDayMemberResourceCount',
        method: 'get',
        params: data
      });
    },
    GET_BYMEMBERRESOURCE_LIST: function GET_BYMEMBERRESOURCE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/getList',
        method: 'get',
        params: data
      });
    },
    GET_BYMEMBERRESOURCE_INFO: function GET_BYMEMBERRESOURCE_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/info',
        method: 'get',
        params: data
      });
    },
    SAVE_BYMEMBERRESOURCE: function SAVE_BYMEMBERRESOURCE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_BYMEMBERRESOURCE: function UPDATE_BYMEMBERRESOURCE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/update',
        method: 'put',
        data: data
      });
    },
    DELETE_BYMEMBERRESOURCE: function DELETE_BYMEMBERRESOURCE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_BYMEMBERRESOURCE: function DELETE_BATCH_BYMEMBERRESOURCE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byMemberResource/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});