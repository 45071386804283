export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 登录
     * @param {Object} data 登录携带的信息
     */
    SYS_USER_LOGIN: function SYS_USER_LOGIN() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/user/login',
        method: 'post',
        data: {
          username: data.username,
          password: data.password,
          loginType: data.loginType
        }
      });
    },
    GET_SYS_USER_LIST: function GET_SYS_USER_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/user/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_USER_INFO: function GET_USER_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/user/userInfo',
        method: 'get',
        params: data
      });
    },
    SAVE_USER: function SAVE_USER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/user/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_USER: function UPDATE_USER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/user/update',
        method: 'put',
        data: data
      });
    },
    DELETE_USER: function DELETE_USER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/user/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_USER: function DELETE_BATCH_USER() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/user/deleteBatch',
        method: 'delete',
        data: data
      });
    },
    UPDATE_USER_PASSWORD: function UPDATE_USER_PASSWORD() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/admin/v1/user/updatePassWord',
        method: 'post',
        data: data
      });
    }
  };
});