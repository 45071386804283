//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import MemberRechargePosition from './list/index';
import OperationSettings from '../operationSettings/list/index';
export default {
  name: 'MemberContent',
  components: {
    MemberRechargePosition: MemberRechargePosition,
    OperationSettings: OperationSettings
  },
  data: function data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    }
  },
  created: function created() {}
};