import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
/**
 *  导入路由
 * @returns
 */


export function exRoutes() {
  var allRoutes = [];
  var routes = localStorage.getItem('routers');

  if (routes != null) {
    var newOutes = JSON.parse(routes);
    newOutes.forEach(function (res) {
      try {
        var newRes = {
          path: res.path,
          name: res.name,
          component: _import(res.componentStr),
          meta: {
            title: res.meta.title
          }
        };
        allRoutes.push(newRes);
      } catch (e) {}
    });
  }

  return allRoutes;
}

function getNetRoutes() {
  var allRoutes = [// 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/main/index')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }, {
    path: '/log',
    name: 'log',
    meta: {
      auth: true
    },
    component: _import('system/log')
  }];
  var pushs = exRoutes();
  pushs.forEach(function (op) {
    allRoutes.push(op);
  });
  return allRoutes;
}
/**
 * 在主框架内显示
 */


var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: getNetRoutes()
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export var routes = [].concat(frameIn, frameOut, errorPage);