/**
 * ��̬��Ϣ������ӿ�
 * @author ������
 * @since 2022-04-30
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_PUSHDYNAMIC_PAGE_LIST: function GET_PUSHDYNAMIC_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushDynamic/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_PUSHDYNAMIC_LIST: function GET_PUSHDYNAMIC_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushDynamic/getList',
        method: 'get',
        params: data
      });
    },
    GET_PUSHDYNAMIC_INFO: function GET_PUSHDYNAMIC_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushDynamic/info',
        method: 'get',
        params: data
      });
    },
    SAVE_PUSHDYNAMIC: function SAVE_PUSHDYNAMIC() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushDynamic/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_PUSHDYNAMIC: function UPDATE_PUSHDYNAMIC() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushDynamic/update',
        method: 'put',
        data: data
      });
    },
    DELETE_PUSHDYNAMIC: function DELETE_PUSHDYNAMIC() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushDynamic/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_PUSHDYNAMIC: function DELETE_BATCH_PUSHDYNAMIC() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/pushDynamic/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});