var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "status-icon": "",
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "常规设置", name: "second" } },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("后台设置")
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "后台名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "后台图标", prop: "logo" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            headers: _vm.uploadHeaders,
                            action:
                              _vm.uploadUrl + "admin/v1/file/uploadFileImg",
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            "before-upload": _vm.beforeAvatarUpload
                          }
                        },
                        [
                          _vm.form.logoImg
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.form.logoImg },
                                on: {
                                  "visible-change": function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ]
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("小程序设置")
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "小程序AppId", prop: "wxMiniAppid" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.wxMiniAppid,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "wxMiniAppid", $$v)
                          },
                          expression: "form.wxMiniAppid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "小程序Secret", prop: "wxMiniSecret" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.wxMiniSecret,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "wxMiniSecret", $$v)
                          },
                          expression: "form.wxMiniSecret"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "小程序名称", prop: "wxMiniName" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.form.wxMiniName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "wxMiniName", $$v)
                          },
                          expression: "form.wxMiniName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "小程序图标", prop: "wxMiniImage" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            headers: _vm.uploadHeaders,
                            action:
                              _vm.uploadUrl + "admin/v1/file/uploadFileImg",
                            "show-file-list": false,
                            "on-success": _vm.wximageHandleAvatarSuccess,
                            "before-upload": _vm.wximageBeforeAvatarUpload
                          }
                        },
                        [
                          _vm.form.WxMinilogoImg
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.form.WxMinilogoImg },
                                on: {
                                  "visible-change": function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务协议", prop: "wxMiniAgreement" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnewTer",
                        attrs: { type: "textarea", autocomplete: "off" },
                        model: {
                          value: _vm.form.wxMiniAgreement,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "wxMiniAgreement", $$v)
                          },
                          expression: "form.wxMiniAgreement"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "隐私政策", prop: "wxMiniPrivacy" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnewTer",
                        attrs: { type: "textarea", autocomplete: "off" },
                        model: {
                          value: _vm.form.wxMiniPrivacy,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "wxMiniPrivacy", $$v)
                          },
                          expression: "form.wxMiniPrivacy"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "editBtn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { disabled: _vm.isUpdate, type: "primary" },
                          on: { click: _vm.update }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "文件储存", name: "first" } },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("储存配置")
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "isColor" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.ossType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "ossType", $$v)
                            },
                            expression: "form.ossType"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "file" } }, [
                            _vm._v("本地")
                          ]),
                          _c("el-radio", { attrs: { label: "ali" } }, [
                            _vm._v("阿里云")
                          ]),
                          _c("el-radio", { attrs: { label: "txy" } }, [
                            _vm._v("腾讯云")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("本地配置")
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "本地路径", prop: "localFile" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.localFile,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "localFile", $$v)
                          },
                          expression: "form.localFile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "访问域名", prop: "localDns" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextfile",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.localDns,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "localDns", $$v)
                          },
                          expression: "form.localDns"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("阿里配置")
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "BucketName", prop: "BucketName" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.aliBucketName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "aliBucketName", $$v)
                          },
                          expression: "form.aliBucketName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Endpoint", prop: "Endpoint" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.aliEndpoint,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "aliEndpoint", $$v)
                          },
                          expression: "form.aliEndpoint"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "accessKeyId", prop: "accessKeyId" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.aliAccessKeyId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "aliAccessKeyId", $$v)
                          },
                          expression: "form.aliAccessKeyId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "accessKeySecret",
                        prop: "accessKeySecret"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.aliAccessKeySecret,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "aliAccessKeySecret", $$v)
                          },
                          expression: "form.aliAccessKeySecret"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "OssDns", prop: "OssDns" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextfile",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.aliOssDns,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "aliOssDns", $$v)
                          },
                          expression: "form.aliOssDns"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("腾讯配置")
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "BucketName", prop: "BucketName" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.txBucketName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "txBucketName", $$v)
                          },
                          expression: "form.txBucketName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Endpoint", prop: "Endpoint" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.txEndpoint,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "txEndpoint", $$v)
                          },
                          expression: "form.txEndpoint"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "accessKeyId", prop: "accessKeyId" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.txAccessKeyId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "txAccessKeyId", $$v)
                          },
                          expression: "form.txAccessKeyId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "accessKeySecret",
                        prop: "accessKeySecret"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputTextnew",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.txAccessKeySecret,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "txAccessKeySecret", $$v)
                          },
                          expression: "form.txAccessKeySecret"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "OssDns", prop: "OssDns" } },
                    [
                      _c("el-input", {
                        staticClass: "inputTextfile",
                        attrs: { st: "", autocomplete: "off" },
                        model: {
                          value: _vm.form.txOssDns,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "txOssDns", $$v)
                          },
                          expression: "form.txOssDns"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "editBtn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { disabled: _vm.isUpdate, type: "primary" },
                          on: { click: _vm.update }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }